import { useNavigation } from '@remix-run/react'
import { useFormContext } from 'react-hook-form'

import BaseTextInput from './BaseTextInput'

import errorMessages from '~/constants/errorMessages'
import formValidations from '~/utils/formValidations.util'

const EmailInput = ({
  label,
  testid,
  helperText,
}: {
  label?: string
  testid?: string
  helperText?: string | React.ReactElement
}) => {
  const form = useFormContext<{ email?: string }>()
  const navigation = useNavigation()

  const {
    register,
    formState: { errors },
  } = form

  const loading = navigation.state !== 'idle'

  return (
    <BaseTextInput
      label={label ?? 'E-mail'}
      helperText={helperText ?? ''}
      error={errors.email?.message}
      disabled={loading}
      data-testid={testid}
      {...register('email', {
        required: errorMessages.REQUIRED,
        validate: (value?: string) => {
          if (!value) return errorMessages.REQUIRED
          if (!formValidations.isEmail(value))
            return errorMessages.INVALID_EMAIL
          return true
        },
      })}
      type="email"
    />
  )
}

export default EmailInput
