import { telephoneOrCellphone } from '@lemonenergy/lemonpie-masks'
import { useNavigation } from '@remix-run/react'
import { useFormContext } from 'react-hook-form'

import BaseTextInput from './BaseTextInput'

import errorMessages from '~/constants/errorMessages'
import formValidations from '~/utils/formValidations.util'

const PhoneNumberInput = ({
  label,
  testid,
}: {
  label?: string
  testid?: string
}) => {
  const form = useFormContext<{ phoneNumber?: string }>()
  const navigation = useNavigation()

  const {
    register,
    formState: { errors },
  } = form

  const loading = navigation.state !== 'idle'

  return (
    <BaseTextInput
      label={label ?? 'Número do seu WhatsApp'}
      mask={
        telephoneOrCellphone as (
          value: string | number | readonly string[],
        ) => Array<string | RegExp>
      }
      error={errors.phoneNumber?.message}
      disabled={loading}
      data-testid={testid}
      {...register('phoneNumber', {
        required: errorMessages.REQUIRED,
        validate: (value?: string) => {
          if (!value) return errorMessages.REQUIRED
          if (!formValidations.isPhoneNumber(value))
            return errorMessages.INVALID_PHONE
          return true
        },
      })}
      type="tel"
    />
  )
}

export default PhoneNumberInput
