export enum PublishersNames {
  oglobo = 'oglobo',
  valor = 'valor',
  canalsolar = 'canalsolar',
  exame = 'exame',
  megawhat = 'megawhat',
  infomoney = 'infomoney',
  otempo = 'otempo',
}

export enum ArticleNames {
  apoioAmbev = 'apoioAmbev',
  lemonAtraiKaszek = 'lemonAtraiKaszek',
  lemonTereosParceria = 'lemonTereosParceria',
  lemonNaviAcordo = 'lemonNaviAcordo',
  geracaoCompartilhadaComerciantes = 'geracaoCompartilhadaComerciantes',
  lemonRevolucionarSetor = 'lemonRevolucionarSetor',
  lemonPMEKazesk = 'lemonPMEKazesk',
  startupQueLevaEnergiaLimpa = 'startupQueLevaEnergiaLimpa',
  lemonConectaPequenosNegocios = 'lemonConectaPequenosNegocios',
  lemonAtende743MunicipiosMineiros = 'lemonAtende743MunicipiosMineiros',
}

export type Publisher = {
  name: PublishersNames
  logo: {
    alt: string
  }
}

export type Article = {
  publisher: Publisher
  title: string
  link: string
}

export const publishers: { [key: string]: Publisher } = {
  oGlobo: {
    name: PublishersNames['oglobo'],
    logo: {
      alt: 'Logo da revista O Globo',
    },
  },
  valor: {
    name: PublishersNames['valor'],
    logo: {
      alt: 'Logo da revista Valor',
    },
  },
  exame: {
    name: PublishersNames['exame'],
    logo: {
      alt: 'Logo da revista Exame',
    },
  },
  canalSolar: {
    name: PublishersNames['canalsolar'],
    logo: {
      alt: 'Logo da revista CanalSolar',
    },
  },
  megawhat: {
    name: PublishersNames['megawhat'],
    logo: {
      alt: 'Logo da revista Megawhat',
    },
  },
  infomoney: {
    name: PublishersNames['infomoney'],
    logo: {
      alt: 'Logo da revista Infomoney',
    },
  },
  otempo: {
    name: PublishersNames['otempo'],
    logo: {
      alt: 'Logo da revista o Tempo',
    },
  },
}

export const articles: { [key in ArticleNames]: Article } = {
  [ArticleNames['apoioAmbev']]: {
    publisher: publishers.oGlobo,
    title:
      'Apoiada pela Ambev, startup de energia limpa Lemon recebe investimento de R$ 60 milhões',
    link: 'https://blogs.oglobo.globo.com/capital/post/apoiada-pela-ambev-startup-de-energia-limpa-lemon-recebe-investimento-de-r-60-milhoes.html',
  },
  [ArticleNames['lemonPMEKazesk']]: {
    publisher: publishers.exame,
    title:
      'Lemon: startup da energia verde para PMEs capta R$ 60 milhões com Kaszek',
    link: 'https://blogs.oglobo.globo.com/capital/post/apoiada-pela-ambev-startup-de-energia-limpa-lemon-recebe-investimento-de-r-60-milhoes.html',
  },
  [ArticleNames['lemonAtraiKaszek']]: {
    publisher: publishers.valor,
    title:
      'Lemon Energia atrai Kaszek e primeiro cheque da Lowercarbon no Brasil',
    link: 'https://pipelinevalor.globo.com/startups/noticia/lemon-energia-atrai-kaszek-e-primeiro-cheque-da-lowercarbon-no-brasil.ghtml',
  },
  [ArticleNames['lemonTereosParceria']]: {
    publisher: publishers.megawhat,
    title:
      'Lemon e Tereos fecham parceria para geração de energia a partir do biogás',
    link: 'https://megawhat.energy/news/147170/lemon-e-tereos-fecham-parceria-para-geracao-de-energia-partir-do-biogas',
  },
  [ArticleNames['lemonNaviAcordo']]: {
    publisher: publishers.valor,
    title: 'Lemon Energia e Navi fecham acordo para gestão de usinas solares',
    link: 'https://valor.globo.com/empresas/noticia/2023/03/07/lemon-energia-e-navi-fecham-acordo-para-gesto-de-usinas-solares.ghtml',
  },
  [ArticleNames['geracaoCompartilhadaComerciantes']]: {
    publisher: publishers.canalSolar,
    title:
      'Geração remota compartilhada ajuda comerciantes a terem energia solar',
    link: 'https://canalsolar.com.br/geracao-remota-compartilhada-ajuda-comerciantes-a-terem-energia-solar',
  },
  [ArticleNames['lemonRevolucionarSetor']]: {
    publisher: publishers.exame,
    title: 'Open Energy: como a startup Lemon quer revolucionar o setor',
    link: 'https://exame.com/exame-in/open-energy-como-a-startup-lemon-quer-revolucionar-o-setor-eletrico/',
  },
  [ArticleNames['startupQueLevaEnergiaLimpa']]: {
    publisher: publishers.infomoney,
    title:
      'Startup que leva energia limpa (e mais barata) a pequenas empresas atrai Ambev.',
    link: 'https://www.infomoney.com.br/negocios/startup-que-leva-energia-limpa-e-mais-barata-a-pequenas-empresas-atrai-ambev/',
  },
  [ArticleNames['lemonConectaPequenosNegocios']]: {
    publisher: publishers.exame,
    title:
      'Com apoio da Ambev, Lemon conecta pequenos negócios à energia limpa.',
    link: 'https://exame.com/inovacao/50-startups-com-apoio-da-ambev-lemon-conecta-pequenos-negocios-a-energia-limpa/',
  },
  [ArticleNames['lemonAtende743MunicipiosMineiros']]: {
    publisher: publishers.otempo,
    title: 'Lemon Energia atende empresas de 743 municípios mineiros.',
    link: 'https://www.otempo.com.br/minas-s-a/lemon-energia-atende-empresas-de-743-municipios-mineiros-1.2643510',
  },
}

export const homeArticles: Array<Article[]> = [
  [
    articles['lemonPMEKazesk'],
    articles['apoioAmbev'],
    articles['lemonAtraiKaszek'],
  ],
]

export const defaultArticles: Array<Article[]> = [
  [
    articles['lemonAtraiKaszek'],
    articles['lemonPMEKazesk'],
    articles['apoioAmbev'],
  ],
]

export const generatorArticles: Array<Article[]> = [
  [
    articles['apoioAmbev'],
    articles['lemonAtraiKaszek'],
    articles['lemonTereosParceria'],
  ],
  [
    articles['lemonNaviAcordo'],
    articles['geracaoCompartilhadaComerciantes'],
    articles['lemonRevolucionarSetor'],
  ],
]

export const gympassArticles: Array<Article[]> = [
  [
    articles['startupQueLevaEnergiaLimpa'],
    articles['lemonConectaPequenosNegocios'],
    articles['lemonAtende743MunicipiosMineiros'],
  ],
]
