import { TextInput } from '@lemonenergy/lemonpie'
import type { Props } from '@lemonenergy/lemonpie/dist/TextInput/TextInput'
import { forwardRef } from 'react'
import styled from 'styled-components'

import { captureInteraction } from '~/utils/tracking'

const StyledTextInput = styled(TextInput)`
  width: 100%;
`

const BaseTextInput = forwardRef(({ onBlur, ...props }: Props, ref) => {
  const trackBlurEvent = (e: React.FocusEvent<HTMLInputElement>) => {
    if (onBlur) onBlur(e)

    captureInteraction()(e)
  }

  return <StyledTextInput ref={ref} onBlur={trackBlurEvent} {...props} />
})

BaseTextInput.displayName = 'BaseTextInput'

export default BaseTextInput
