import { useNavigation } from '@remix-run/react'
import { useFormContext } from 'react-hook-form'

import BaseTextInput from './BaseTextInput'

import errorMessages from '~/constants/errorMessages'
import formValidations from '~/utils/formValidations.util'

const NameInput = ({ label, testid }: { label?: string; testid?: string }) => {
  const form = useFormContext<{ fullName?: string }>()
  const navigation = useNavigation()

  const {
    register,
    formState: { errors },
  } = form

  const loading = navigation.state !== 'idle'

  return (
    <BaseTextInput
      label={label ?? 'Seu nome'}
      error={errors.fullName?.message}
      disabled={loading}
      data-testid={testid}
      {...register('fullName', {
        required: errorMessages.REQUIRED,
        validate: (value?: string) => {
          if (!value) return errorMessages.REQUIRED
          if (!formValidations.isFullName(value)) return errorMessages.FULL_NAME
          return true
        },
      })}
    />
  )
}

export default NameInput
